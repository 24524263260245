/* General - START */
.container-inner {
  // margin-right: 25px;
}

.blue-link {
  color: #00aeef;

  &:hover {
    color: #00aeef !important;
    text-decoration: underline !important;
  }
}
.element-with-blur {
  position: relative;
  @include dark-blur;
}

.transparent-bg {
  background: transparent !important;
}

.padded {
  padding: 20px;
}

.marginless {
  margin: 0 !important;
}

dl {
  width: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
}
dt {
  float: left;
  width: 50%;
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
  clear: both;
  word-wrap: break-word;
}
dd {
  float: left;
  width: 50%;
  padding: 0;
  margin: 0;
  text-align: right;
  margin-bottom: 10px;
  word-wrap: break-word;
}

tr.danger-row {
  td {
    background: rgba(181, 90, 90, 0.61);
  }
}

tr.success-row {
  td {
    background: rgba(86, 181, 66, 0.61);
  }
}
/* General - END */

/* Buttons - START */
.button {
  display: inline-block;
  border: 1px solid #fff;
  padding: 5px 15px 5px 15px;
  text-align: center;
  color: #fff;
  margin: auto 5px;
  background: transparent;
  border-radius: 5px;
  cursor: pointer;
  z-index: 10;
  @include trans;
  &:hover {
    border-color: $blue-hover;
    color: $blue-hover;
  }
  @include laptop {
    padding: 5px 8px;
  }
  @include ipad {
    padding: 3px;
  }
}
.button-round {
  @extend .button;
}
.button-inline {
  max-width: auto;
  width: auto;
  display: inline-block;
}
.button-small {
  padding: 4px 8px;
  font-size: 0.9em;
}
.button-success {
  @extend .button;
  border: 1px solid rgba(86, 181, 66, 0.8);
  color: #fff !important;
  background: rgba(86, 181, 66, 0.8);
  &:hover {
    background: lighten(rgba(86, 181, 66, 0.8), 15%);
    border-color: white;
    color: white;
  }
}
.button-danger {
  @extend .button;
  border: 1px solid #b74343;
  color: #fff !important;
  background: #b74343;
  &:hover {
    background: lighten(#b74343, 15%);
    border-color: white;
    color: white;
  }
}
.button-info {
  @extend .button;
  border: none;
  color: #fff;
  background: #459cb7;
  &:hover {
    background: lighten(#459cb7, 15%);
    border-color: white;
    color: white;
  }
}
.remove-radius-left {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.remove-radius-right {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
/* Buttons - END */

/* Form - START */
form {
  width: 100%;
}
.form-field {
  display: flex;
  margin-bottom: 20px;
  label {
    color: #fff;
    font-family: $lato;
    font-weight: 300;
    min-width: 200px;
    margin: auto 0 auto 0 !important;
    vertical-align: middle;
  }
  input,
  textarea {
    background: transparent;
    border: 1px solid #85898a;
    outline: none;
    box-shadow: none;
    color: #fff;
    padding-left: 10px;
    font-family: $lato;
    margin-bottom: 0;
  }
  textarea {
    margin: 0 !important;
  }
  &.horizontal-input {
    display: block;
    label {
      display: block;
      min-width: initial;
      width: 100%;
    }
    input {
      display: block;
      min-width: initial;
      width: 100%;
    }
  }
}
/* Form - END */

/* React Modal - START */

.ReactModal__Body--open {
  overflow: hidden;
}
.react-modal-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99999999;
  .react-modal-content {
    position: relative;
    top: 50%;
    border: 1px solid darken($gray-dark, 10%);
    background: $gray-dark;
    overflow: auto;
    outline: none;
    padding: 20px;
    margin: auto;
    max-width: 800px;
    transform: translateY(-50%);
    color: white;
    h2 {
      color: $blue-hover;
      font-size: 34px;
      margin-top: 20px;
      margin-bottom: 30px;
      text-transform: uppercase;
      font-weight: 300;
    }
    p {
      margin-bottom: 3px;
    }
    .fa-star {
      color: $blue-hover;
      &:last-child {
        color: lighten($blue-hover, 40%);
      }
    }
    .map {
      margin-top: 20px;
    }
    .modal-nav-holder {
      position: absolute;
      right: 20px;
      max-width: 160px;
      width: 160px;
      display: flex;
      a {
        font-weight: 300;
        margin: 0 auto;
        @include trans;
        &:hover {
          color: $blue-hover;
        }
        &:first-child {
          &::before {
            content: '<';
          }
        }
        &:last-child {
          &::after {
            content: '>';
          }
        }
      }
      p {
        font-weight: 300;
        margin: 0 auto;
      }
    }
    .react-modal-footer {
      position: relative;
      text-align: right;
      border-top: 1px solid white;
      padding: 15px 0 15px 0;
    }
  }
}
/* React Modal - END */

.filter-field {
  display: inline-block;
  position: relative;
  margin-top: 10px;
  margin-right: 15px;

  .formRow {
    position: relative;
    background: rgba(255, 255, 255, 0.6);
    height: 35px;
    padding: 5px;
    outline: none;
    width: 100%;
    min-width: 260px;
  }
}

.filter-apply {
  text-align: right;
  display: inline-block;
}

.datetimepicker-filter-field {
  display: inline-block;
  width: auto;
  margin: 10px 10px 10px 0;

  label {
    color: white;
    display: block;
    margin: auto;
    text-align: center;
    margin-bottom: 5px;
  }

  input {
    margin: 2px;
    background: #59616b;
    box-shadow: none;
    outline: none;
    border: 1px solid white;
    color: white;
    text-align: center;
    font-family: 'Lato';
    height: 28px;
    font-size: 14px;
    padding: 2px;
  }
}

.form-button-container {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 20px 0;
}
.submitButtonWrapper {
  margin-top: 30px;
}
.input-wrap {
  padding-top: 18px;
  z-index: 999999;
  position: relative;
}
.input-wrap-country {
  padding-top: 28px;
}
.input-wrap-mobile {
  padding-top: 10px;
}
.MuiInput-underline.Mui-error:after {
  border-bottom-color: #f44336 !important;
}
.custom-btn {
  border-radius: 5px !important;
  background-color: transparent !important;
  color: white !important;
  width: 100%;
  height: 100%;
  min-height: 55px;
  max-height: 600px;
  max-width: 520px;
  outline: none !important;
  font-size: 16px !important;
  text-transform: uppercase !important;
  border: 1px solid white !important;
  .circle-progress {
    color: white;
  }
}
.custom-danger-btn {
  border-radius: 5px !important;
  background-color: #b74343 !important;
  color: white !important;
  width: 100%;
  height: 100%;
  min-height: 55px;
  max-height: 600px;
  max-width: 520px;
  outline: none !important;
  font-size: 16px !important;
  text-transform: uppercase !important;
  border: 1px solid white !important;
  .circle-progress {
    color: white;
  }
}
#outlined-error {
  .validation-message {
    bottom: -11px;
  }
  ::-webkit-input-placeholder {
    color: #f44336 !important;
  }
  :-moz-placeholder {
    color: #f44336 !important;
  }
  ::-moz-placeholder {
    color: #f44336 !important;
  }
  :-ms-input-placeholder {
    color: #f44336 !important;
  }
}

.toast-mesaage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 99999;
  display: flex;
  align-items: center;
  box-shadow: 10px 7px 18px 0 rgba(85, 84, 173, 0.5);
  height: 60px;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -ms-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
  p {
    margin: 0;
    padding: 0;
    width: 100%;
  }
  .close-icon {
    position: absolute;
    right: 20px;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    color: $blue-hover;
    cursor: pointer;
  }
}

.squaredTwo {
  width: 28px;
  height: 28px;
  position: relative;
  margin: 20px auto;
  background: #fff;
  border: 1px solid #979797;
  label {
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    left: 4px;
    top: 4px;
    color: white;
    &:after {
      content: '';
      width: 14px;
      height: 8px;
      position: absolute;
      top: 3px;
      left: 2px;
      transition: opacity 0.3s ease;
      border: 3px solid $gray-dark;
      border-top: none;
      border-right: none;
      background: transparent;
      opacity: 0;
      transform: rotate(-45deg);
    }
    &:hover::after {
      opacity: 0.3;
    }
  }
  input[type='checkbox'] {
    visibility: hidden;
    &:checked + label:after {
      opacity: 1;
    }
  }
}
