@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}
.amountInput {
  width: 100px;
  margin-left: 15px;
  @include placeholder {
    color: #495057;
  }
}


.load-report{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  input{
    width: 100%;
    height: 100%;
    min-height: 72px;
    opacity: 0;
    cursor: pointer;
  }
}
